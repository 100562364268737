const idPortalApi = "4d8ac0fdd58bdc847ab2c38b4cf16f6811551f4e";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://cdn.administracaopublica.com.br/complementares/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://cmmirinzal.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmmirinzal.ma.gov.br",
  NomePortal: "Mirinzal",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 6, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/hj27r21H1aK8AUuz9`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15948.898093453603!2d-44.7903252042986!3d-2.066105962943391!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7f7c5de3da549af%3A0x79b5e52d2c149945!2sMirinzal%2C%20State%20of%20Maranh%C3%A3o%2C%2065265-000!5e0!3m2!1sen!2sbr!4v1737135808622!5m2!1sen!2sbr",
};
